<template>
  <b-card

    title="Edit Template"
  >

    <validation-observer ref="simpleRules">

      <b-form @submit.prevent>
        <b-row>

          <b-col cols="6">
            <b-form-group
              label="Big Query Cloumn"
              label-for="v-bigQueryCloumn"
            >
              <validation-provider
                #default="{ errors }"
                name="Cloumn Name"
                rules="required|alpha_dash"
              >
                <b-form-input
                  id="v-bigQueryCloumn"
                  v-model="column.bigQueryCloumn"
                  placeholder="Big Query Cloumn"
                  required
                  :state="errors.length > 0 ? false:null"
                />

                <span class="text-danger"> {{ errors[0] }} </span>

              </validation-provider>

            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="Document Cloumn"
              label-for="v-documentCloumn"
            >
              <validation-provider
                #default="{ errors }"
                name="documentCloumn"
                rules="required"
              >
                <b-form-input
                  id="v-documentCloumn"
                  v-model="column.documentCloumn"
                  placeholder="Document Cloumn"
                  required
                  :state="errors.length > 0 ? false:null"
                />

              </validation-provider>

            </b-form-group>
          </b-col>
          <!-- submit and reset -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              :disabled="loading"
              @click="saveColumn"
            >
              Save
            </b-button>

          </b-col>

        </b-row>

      </b-form>

    </validation-observer>

  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormSelect, BFormCheckbox, BForm, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  required,
  alpha_num,
  alpha_dash,
} from 'vee-validate/dist/rules'

extend('required', required)
extend('alpha_num', alpha_num)
extend('alpha_dash', alpha_dash)

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BForm,
    BButton,
    BCard,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      column: {},
      loading: false,
    }
  },
  mounted() {
    this.loadColumn(this.$route.params.columnId)
  },
  methods: {

    loadColumn(columnId) {
      this.$store.dispatch('documentColumn/get', columnId)
        .then(result => {
          this.column = result.data
        },
        error => {

        })
    },

    saveColumn() {
      this.$refs.simpleRules.validate().then(valid => {
        if (valid) {
          this.loading = true

          this.$store.dispatch('documentColumn/update', this.column)
            .then(() => {
              this.$router.push({ name: 'documents-columns', params: { templateId: this.column?.documentTemplateId } })
            },
            error => {
              this.showToast('Error', error.message || 'Error while saving', 'danger')
            }).finally(() => {
              this.loading = false
            })
        } else {
          this.showToast('Form', 'Check your inputs please', 'warning')
        }
      }, error => {
        console.log(error)
      })
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'InfoIcon',
          text,
          variant,
        },
      })
    },
  },
}
</script>
